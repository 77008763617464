import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import BlogAnchorNav from "./BlogAnchorNav";
import Link from "@mui/material/Link";
import { blogHeaderImages, authors } from "../../utils/imageUtils";
import Header from "./../../pages/LandingPage/Header";
import Footer from "./../../pages/LandingPage/Footer";
import "./index.css";

const BlogOne = () => {
    const { t } = useTranslation();

    const sections = [
        { id: "section1", title: t("blogOne.blogOneTitle") },
        { id: "section2", title: t("blogOne.secondParagraphTitle") },
        { id: "section3", title: t("blogOne.thirdParagraphTitle") },
        { id: "section4", title: t("blogOne.fourthParagraphTitle") },
        { id: "section5", title: t("blogOne.fifthParagraphTitle") },
    ];

    const series = [
        { label: "B2C", name: "Series 1", data: [32, 44, 55], color: "#2A52BE" },
        { label: "B2B", name: "Series 2", data: [30, 41, 53], color: "#007BA7" },
    ];

    const imageMap = {
        header_img: blogHeaderImages.header_img_article_1,
        oksana_author: authors.oksana_author
    };

    const links = {
        blog: "/blog",
        authorLink: "https://www.linkedin.com/in/oksana-budz?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    }; 

    const yAxisConfig = [
        {
            valueFormatter: (value) => `${value}%`
        }
    ]

    return (
        <>
            <Header />
            <Box className="breadcrumbs-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" href={links.blog} className="breadcrumbs-link">
                        {t("blogTwo.breadcrumbsLinkBlog")}
                    </Link>
                    <Typography color="text.primary">{t("blogTwo.blogOneTitle")}</Typography>
                </Breadcrumbs>
            </Box>
            <div className="blog-post-title-container">
                <div className="left-section">
                    <Typography className="blog-post-title" variant="h1" gutterBottom>
                        {t("blogOne.blogOneTitle")}
                    </Typography>
                    <Typography className="blog-post-subtitle" variant="h2" gutterBottom>
                        {t("blogOne.blogOneSubtitle")}
                    </Typography>
                    <div className="meta-info">
                        <Typography variant="subtitle1">{t("blogOne.blogPostDate")}</Typography>
                        <Typography variant="subtitle1">•</Typography>
                        <Typography variant="subtitle1">{t("blogOne.blogPostTimeRead")}</Typography>
                        <Typography variant="subtitle1">•</Typography>
                        <Typography variant="subtitle1">Updated in January 2025</Typography>
                    </div>
                </div>
                <div className="right-section">
                    <section className="blog-image">
                        <img src={imageMap.header_img} alt="A rising bar chart with an upward arrow, symbolizing the growth of artificial intelligence (AI), accompanied by a brain illustration connected to various data and technology icons" />
                    </section>
                </div>
            </div>
            <Grid container spacing={2} className="blog-post-layout">
            <Grid item xs={12} md={3}>
                    {/* Author Section */}
                    <Box className="author-info">
                        <Box mr={2}>
                            <img src={imageMap.oksana_author} alt="Oksana, Head of Content" />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"><strong>Oksana, Head of Content</strong></Typography>
                            <Link href={links.authorLink} target="_blank" rel="noopener noreferrer" className="url">
                                LinkedIn Profile
                            </Link>
                        </Box>
                    </Box>
                    <BlogAnchorNav sections={sections} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box id="section1">
                        <Typography variant="body1">
                            {t("blogOne.firsParagraphtLineOne")} <strong>{t("blogOne.firsParagraphtLineOneStrong")}</strong>. {t("blogOne.firsParagraphtLineTwo")}<strong> {t("blogOne.firsParagraphtLineTwoStrong")}</strong> {t("blogOne.firsParagraphtLineThree")} <a href="https://www.salesforce.com/content/dam/web/en_us/www/documents/commerce-cloud/salesforce-state-of-commerce-third-edition.pdf" className="url"> {t("blogOne.firsParagraphtLineLink")}</a> {t("blogOne.firsParagraphtLineFour")} </Typography>
                    </Box>
                    <Box className="blog-post-container">
                        <Typography variant="body1" >{t("blogOne.FirstBlogPostContainerBackgroundUl")}</Typography>
                        <ul>
                            <li><strong>{t("blogOne.FirstBlogPostContainerBackgroundLiOneStrong")}</strong> {t("blogOne.FirstBlogPostContainerBackgroundLiOne")}</li>
                            <li><strong>{t("blogOne.FirstBlogPostContainerBackgroundLiTwoStrong")}</strong>. {t("blogOne.FirstBlogPostContainerBackgroundLiTwo")}</li>
                            <li><strong>{t("blogOne.FirstBlogPostContainerBackgroundLiThreeStrong")}</strong> {t("blogOne.FirstBlogPostContainerBackgroundLiThree")}</li>
                        </ul>
                        <Typography variant="body1" >{t("blogOne.FirstBlogPostContainerBackgroundParagraph")} <strong>{t("blogOne.FirstBlogPostContainerBackgroundParagraphStrong")}</strong></Typography>

                    </Box>
                    <Box id="section2" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogOne.secondParagraphTitle")}</Typography>
                        <Typography variant="body1">{t("blogOne.secondParagraphtLineOne")}</Typography>

                    </Box>
                    <Box className="media-container">
                        <Typography variant="body1" className="media-title" gutterBottom>{t("blogOne.chartsTitle")}</Typography>
                        <Typography variant="body2" className="media-paragraph" gutterBottom>{t("blogOne.chartsName")}
                        </Typography>
                        <BarChart
                            xAxis={[{ scaleType: "band", data: ["total 31%", "total 42%", "total 54%"] }]}
                            yAxis={yAxisConfig}
                            series={series}
                            width={380}
                            height={300}
                            axisHighlight={{ x: "none", y: "none" }}

                        />
                        <Typography variant="body1">{t("blogOne.chartsSource")}</Typography>
                    </Box>
                    <Box id="section3" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogOne.thirdParagraphTitle")}</Typography>
                        <Typography variant="body1">{t("blogOne.thirdParagraphtLineOne")}</Typography>

                    </Box>
                    <Box className="media-container">
                        <Typography variant="body1" className="media-title" gutterBottom>{t("blogOne.ulListRowTitle")}</Typography>
                        <Typography variant="body2" className="media-paragraph" gutterBottom>{t("blogOne.ulListRowName")}</Typography>
                        <div>
                            <Typography className="ol-list-row"><span className="number-span">1</span><span className="text-span">{t("blogOne.ulListRowOne")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">2</span><span className="text-span">{t("blogOne.ulListRowTwo")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">3</span><span className="text-span">{t("blogOne.ulListRowThree")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">4</span><span className="text-span">{t("blogOne.ulListRowFour")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">5</span><span className="text-span">{t("blogOne.ulListRowFive")}</span></Typography>
                        </div>
                        <Typography variant="body1">{t("blogOne.ulListSource")}</Typography>
                    </Box>
                    <Box className="blog-post-container">
                        <Typography variant="body1" >{t("blogOne.secondBlogPostContainerBackgroundUl")}</Typography>
                        <ul>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiOneStrong")}</strong> {t("blogOne.secondBlogPostContainerBackgroundLiOne")}</li>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiTwoStrong")}</strong>{t("blogOne.secondBlogPostContainerBackgroundLiTwo")}</li>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiThreeStrong")}</strong>{t("blogOne.secondBlogPostContainerBackgroundLiThree")}</li>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiFourStrong")}</strong>{t("blogOne.secondBlogPostContainerBackgroundLiFour")}</li>
                        </ul>
                    </Box>
                    <Box id="section4" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogOne.fourthParagraphTitle")}</Typography>
                        <Typography variant="body1">{t("blogOne.fourthParagraphtLineOne")}</Typography>

                    </Box>
                    <Box className="blog-post-container">
                        <Typography variant="body1" >
                            {t("blogOne.thirdBlogPostContainerBackgroundUl")}</Typography>
                        <ul>
                            <li><strong> {t("blogOne.thirdBlogPostContainerBackgroundLiOneStrong")}</strong> {t("blogOne.thirdBlogPostContainerBackgroundLiOne")}</li>
                            <li><strong>{t("blogOne.thirdBlogPostContainerBackgroundLiTwoStrong")}</strong>{t("blogOne.thirdBlogPostContainerBackgroundLiTwo")}</li>
                            <li><strong>{t("blogOne.thirdBlogPostContainerBackgroundLiThreeStrong")}</strong>{t("blogOne.thirdBlogPostContainerBackgroundLiThree")}</li>
                        </ul>
                    </Box>
                    <Box id="section5" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogOne.fifthParagraphTitle")}</Typography>
                        <Typography variant="body1">{t("blogOne.fifthParagraphtLineOne")}
                            <br /><br />
                            {t("blogOne.fifthParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="blog-post-container">
                        <Typography variant="body1" >
                            {t("blogOne.fourthBlogPostContainerBackgroundLiOne")} <strong>{t("blogOne.fourthBlogPostContainerBackgroundLiOneStrong")}
                            </strong>{t("blogOne.fourthBlogPostContainerBackgroundLiTwo")} <strong>{t("blogOne.fourthBlogPostContainerBackgroundLiTwoStrong")}</strong></Typography>
                    </Box>
                    <Box id="section6" className="blog-paragraph">
                        <Typography variant="body1">{t("blogOne.sixthParagraphtLineTwo")}
                            <br /><br />
                            <a href="https://calendly.com/d/cj8h-wsc-8yg/Intelswift-demo">{t("blogOne.lastLinkParagraf")}</a> <strong>{t("blogOne.lastStrongParagraf")}</strong> </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default BlogOne;
