import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { BarChart } from '@mui/x-charts/BarChart';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import BlogAnchorNav from "./BlogAnchorNav";
import Link from "@mui/material/Link";
import Header from './../../pages/LandingPage/Header';
import Footer from './../../pages/LandingPage/Footer';
import { blogHeaderImages, secondArticle, authors } from "../../utils/imageUtils";
import "./index.css";

const BlogTwo = () => {
    const { t } = useTranslation();

    const sections = [
        { id: "section1", title: "Top 8 use cases where AI shines the most" },
        { id: "section2", title: "Few more benefits from using AI in customer service" },
    ];

    const series = [
        {
            name: 'Data',
            data: [20, 70],
            color: ['#2A52BE', '#007BA7']
        }
    ];

    const imageMap = {
        header_img: blogHeaderImages.header_img_article_2,
        oksana_author: authors.oksana_author,
        img_1: secondArticle.article_2_img_1
    };


    const links = {
        blog: "/blog",
        authorLink: "https://www.linkedin.com/in/oksana-budz?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    }; 

    const xAxisConfig = [{
        scaleType: 'band',
        data: ['2023', '2024']
    }];

    const yAxisConfig = [
        {
            valueFormatter: (value) => `${value}%`
        }
    ]

    return (
        <>
            <Header />
            <Box className="breadcrumbs-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover"  href={links.blog} className="breadcrumbs-link">
                        {t("blogTwo.breadcrumbsLinkBlog")}
                    </Link>
                    <Typography color="text.primary">{t("blogTwo.blogOneTitle")}</Typography>
                </Breadcrumbs>
            </Box>
            <div className="blog-post-title-container">
                <div className="left-section">
                    <Typography className="blog-post-title" variant="h1" gutterBottom>
                        {t("blogTwo.blogOneTitle")}
                    </Typography>
                    <Typography className="blog-post-subtitle" variant="h2" gutterBottom>
                        {t("blogTwo.blogOneSubtitle")}
                    </Typography>
                    <div className="meta-info">
                        <Typography variant="subtitle1">{t("blogTwo.blogPostDate")}</Typography>
                        <Typography variant="subtitle1">•</Typography>
                        <Typography variant="subtitle1">{t("blogOne.blogPostTimeRead")}</Typography>
                        <Typography variant="subtitle1">•</Typography>
                        <Typography variant="subtitle1">Updated in January 2025</Typography>
                    </div>
                </div>
                <div className="right-section">
                    <section className="blog-image">
                        <img src={imageMap.header_img} alt="A futuristic office environment where employees are working at their desks alongside holographic AI assistants, symbolizing the integration of artificial intelligence in the workplace"/>
                    </section>
                </div>
            </div>
            <Grid container spacing={2} className="blog-post-layout">
            <Grid item xs={12} md={3}>
                    {/* Author Section */}
                    <Box className="author-info">
                        <Box mr={2}>
                            <img src={imageMap.oksana_author} alt="Oksana, Head of Content" />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"><strong>Oksana, Head of Content</strong></Typography>
                            <Link href={links.authorLink} target="_blank" rel="noopener noreferrer" className="url">
                                LinkedIn Profile
                            </Link>
                        </Box>
                    </Box>
                    <BlogAnchorNav sections={sections} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box id="section1">
                        <Typography variant="body1">
                        {t("blogTwo.firsParagraphtLineOne")} <a href="https://go.emplifi.io/rs/284-ENW-442/images/Emplifi_Report_Consumer_Expectations_US_UK_EN.pdf" className="url">{t("blogTwo.firsParagraphtLink")}</a> {t("blogTwo.firsParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="media-container">
                        <img className='small-img' src={imageMap.img_1} alt="A table from Emplifi showing consumer expectations for response times from brands on digital channels. It compares the average, US, and UK data for responses within 5 minutes and within an hour"/>
                        <Typography variant="body1">{t("blogTwo.tableSource")}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1">{t("blogTwo.secondParagraphtLineOne")} <a href="https://downloads.ctfassets.net/xny2w179f4ki/3FxNFG5dIUBgphM6xqLgPy/ecfaca62ff0550e4d345b31addbff762/Intercom_Customer_Service_Trends_Report_2024.pdf" className="url">{t("blogTwo.secondParagraphtLink")}</a> {t("blogTwo.secondParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="media-container">
                        <BarChart
                            xAxis={xAxisConfig}
                            yAxis={yAxisConfig}
                            series={series}
                            width={390}
                            height={300}
                            axisHighlight={{ x: 'none', y: 'none' }}
                        />
                        <Typography variant="body1" gutterBottom>{t("blogTwo.chartSource")}</Typography>
                        <Typography variant="body1">{t("blogTwo.thirdParagraphtLineOne")}</Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesOnwAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesOneAiShinesParagraphOne")}
                            <br /><br />{t("blogTwo.casesOneAiShinesParagraphTwo")}</Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesTwoAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesTwoAiShinesParagraphOne")}</Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesThreeAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesThreeAiShinesParagraphOne")}</Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesFourAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesFourAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesFiveAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesFiveAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesSixAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesSixAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesSevenAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesSevenAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="ol-container">
                        <Typography variant="body2" className="media-paragraph">{t("blogTwo.casesEightAiShinesTitle")}</Typography>
                        <Typography variant="body1">{t("blogTwo.casesEightAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box id="section2" className="blog-paragraph">
                        <Typography variant="h4" gutterBottom>{t("blogTwo.fourthParagraphtTitle")}</Typography>
                        <Typography variant="body1">
                            {t("blogTwo.fourthParagraphtLineOne")} <a href="https://cxtrends.zendesk.com" className="url"> {t("blogTwo.fourthParagraphtLink")}</a> {t("blogTwo.fourthParagraphtLineTwo")}</Typography>
                    </Box>
                        <ul>
                            <li><strong>{t("blogTwo.containerBackgroundlistOneStrong")}</strong> {t("blogTwo.containerBackgroundlistOne")}</li>
                            <li><strong>{t("blogTwo.containerBackgroundlistTwoStrong")}</strong> {t("blogTwo.containerBackgroundlistTwo")}</li>
                            <li><strong>{t("blogTwo.containerBackgroundlistThreeStrong")}</strong> {t("blogTwo.containerBackgroundlistThree")}</li>

                        </ul>
                    <Box>
                        <Typography variant="body1">
                            {t("blogTwo.fifthParagraphtLineOne")} <a href="https://Intelswift.com" className="url">{t("blogTwo.fifthParagraphtLink")}</a> {t("blogTwo.fifthParagraphtLineTwo")}
                            <br /><br />
                            <a href="https://calendly.com/d/cj8h-wsc-8yg/Intelswift-demo?month=2024-09" className="url">Book a demo</a> <strong>{t("blogTwo.lastStrongParagraf")}</strong> </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}
export default BlogTwo